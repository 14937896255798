.navbar .navbar-primary .navbar-button {
    color: white;
    text-transform: none;
    overflow: initial;
}

.navbar .navbar-title {
    flex-grow: 1;
}

.navbar .navbar-secondary {
    background: white;
    color: black;
    z-index: -1;
}