.list-shows .list-shows__item {
    padding: 1px;
    height:100px;
}

.list-shows .list-shows__item img, .list-shows .list-shows__item__image-placeholder {
    background-color: #DDD;
    width: 11rem;
    min-height: 6rem;
    height: 100%;
    object-fit: cover;
}

.list-shows .list-shows__item__text {
    margin: 0 1rem;
}

.list-shows .list-shows__item__edit {
    align-self: stretch;
    align-items: center;
    background-color: darkgray;
    cursor: pointer;
    display: none;
    justify-content: center;
    padding: 2rem;
    width: 30px;
}

.list-shows .list-shows__item:hover {
    background-color: lightgray;
    outline: 1px solid darkgray;
}

.list-shows .list-shows__item:hover .list-shows__item__edit {
    display: flex;
}

@media only screen and (max-width: 600px) {
    .list-shows .list-shows__item__edit {
        padding: wrem;
    }
}