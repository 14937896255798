body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eee;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.home .section-title {
  padding-bottom: 1rem;
}

.home .form-input {
  padding: 0 2rem;
}

.home .form-input__image-upload {
  display: flex;
  width: 100%;
}

.home .form-input__image {
  background: lightgray;
  min-height: 5rem;
  margin-right: 1rem;
  width: 10rem;
}

.overflow-card {
  overflow: initial !important;
  position: relative;
}

.overflow-card .overflow-card__content {
  padding: 0;
}

.overflow-card .overflow-card__actions {
  bottom: -1.5rem;
  position: absolute;
  right: .5rem;
}