.collection-show.collection-show--active {
    background-color: rgba(0, 0, 0, 0.08);
    outline: 1px solid gray;
}

.collection-show.collection-show--delete {
    align-items: center;
    display: flex;
}

.collection-show .collection-show__confirm-delete {
    background-color: lightgray;
    cursor: pointer;
    display: flex;
    padding: 1rem;
    height: calc(100% - 2rem);
    right: 0px;
}

.collection-show .collection-show__confirm-delete .collection-show__confirm-delete__inner {
    align-items: center;
    display: flex;
    justify-content: center;
}